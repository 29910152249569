import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import { SERVER_HIT } from "../util/API";
import { useSelector } from "react-redux";
import { get } from "lodash";
import moment from "moment";

const AcademyReport = () => {
  const { sportList, batchList } = useSelector((state) => state.academy);
  const [ showLoader, setLoader ] = useState(false);
  const [ filters, setFilters ] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    batch: '',
    sport: '',
  });
  const [ academyStudentReportList, setAcademyStudentReportList ] = useState(null);

  useEffect(() => {
    getAcademyReport();
  }, [])

  const getAcademyReport = async () => {
    setLoader(true);
    setAcademyStudentReportList(null);
    const { status, data } = await SERVER_HIT.post('?module=academy&operation=academy_report', {
      startDate: filters.startDate,
      endDate: filters.endDate,
      batch: filters.batch,
      sport: filters.sport,
    });
    if (status === 200) {
      setAcademyStudentReportList(data);
    }
    setLoader(false);
  }

  const getSportName = (sportId) => {
    return get(sportList.find(({sport_id}) => sport_id === sportId), 'sport_name', '');
  }

  return (
    <>
      <Row className="px-1 py-1">
        <Col className="col-12 fs-5 pt-1 fb mb-3">
          <b>Academy Student Report</b>
        </Col>
        
        <Col className="col-5 d-flex">
          <dib className="p-1"><b>Date</b>&nbsp;</dib>
          <Form.Group className="mb-3 mr-2">
            <Form.Control
              type="date"
              name="start_date"
              autoFocus
              value={filters.startDate}
              onChange={(event) => {
                if (event.target.value !== '') {
                  setFilters({
                    ...filters,
                    startDate: moment(event.target.value).format('YYYY-MM-DD')
                  })
                }
              }}
              required
            />
            <Form.Control.Feedback type="invalid">Please enter valid start date</Form.Control.Feedback>
          </Form.Group>

          <div className="p-2 pr-4 pl-4">To</div>

          <Form.Group className="mb-3">
            <Form.Control
              type="date"
              min={filters.startDate}
              name="end_date"
              autoFocus
              value={filters.endDate}
              onChange={(event) => {
                if (event.target.value !== '') {
                  setFilters({
                    ...filters,
                    endDate: moment(event.target.value).format('YYYY-MM-DD')
                  })
                }
              }}
              required
            />
            <Form.Control.Feedback type="invalid">Please enter valid start date</Form.Control.Feedback>
          </Form.Group>  
        </Col>

        <Col className="col-3 d-flex">
          <div className="p-1"><b>Batch</b>&nbsp;&nbsp;</div>
          <Form.Group className="mb-3">
            <Form.Select
              name="batch_id"
              value={filters.batch}
              onChange={(event) => {
                setFilters({
                  ...filters,
                  batch: event.target.value
                })
              }}
              required
            >
              <option value=''>All Batch</option>
              {
                batchList.map(({ batch_id, batch_name }) => <option key={`batch_${batch_id}`} value={batch_id}>{batch_name}</option>)
              }
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select batch</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className="col-3 d-flex">
          <div className="p-1"><b>Sport</b>&nbsp;&nbsp;</div>
          <Form.Group className="mb-3">
            <Form.Select
              name="sport_id"
              value={filters.sport}
              onChange={(event) => {
                setFilters({
                  ...filters,
                  sport: event.target.value
                })
              }}
              required
            >
              <option value=''>All Sport</option>
              {
                sportList.map(({ sport_id, sport_name }) => <option key={`school_${sport_id}`} value={sport_id}>{sport_name}</option>)
              }
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select sport</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className="col-1">
          <Button
            size="md"
            variant="primary"
            onClick={getAcademyReport}
            disabled={showLoader}
          >Search</Button>
        </Col>
      </Row>
      <div>
        {
          academyStudentReportList !== null && academyStudentReportList.length > 0 && (
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" width="15%" className="text-center">Student Name</th>
                  <th scope="col" width="13%" className="text-center">Whatsapp Number</th>
                  <th scope="col" width="10%" className="text-center">Phone Number</th>
                  <th scope="col" width="10%" className="text-center">Sport</th>
                  <th scope="col" width="15%" className="text-center">Batch</th>
                  <th scope="col" width="17%" className="text-center">Membership Duration</th>
                  <th scope="col" width="10%" className="text-center">Fees</th>
                  <th scope="col" width="12%" className="text-center">Fees Paid On</th>
                </tr>
              </thead>
              <tbody>
                {
                  academyStudentReportList.map((reportRow) => {
                    return (
                      <tr key={reportRow.academy_student_fees_id}>
                        <td className="text-center">{reportRow.student_name}</td>
                        <td className="text-center">{reportRow.whatsapp_mobile_number}</td>
                        <td className="text-center">{reportRow.phone_number}</td>
                        <td className="text-center">{
                          getSportName(reportRow.sport_id)  
                        }</td>
                        <td className="text-center">{reportRow.batch_name}</td>
                        <td className="text-center">
                          <Badge className="btn-outline-secondary">
                            {moment(reportRow.start_date).format("DD-MM-YYYY")}
                          </Badge>
                          &nbsp;To&nbsp;
                          <Badge>
                            {moment(reportRow.end_date).format("DD-MM-YYYY")}
                          </Badge>
                        </td>
                        <td className="text-center">₹{reportRow.amount_paid}</td>
                        <td className="text-center">{moment(reportRow.paid_on).format("DD-MM-YYYY")}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          )
        }
        {
          academyStudentReportList !== null && academyStudentReportList.length === 0 && (
            <Card className="mt-5">
              <div className="p-5 text-center">Oops!! No Data Available.</div>
            </Card>
          )
        }
      </div>
    </>
  );
};

export default AcademyReport;
