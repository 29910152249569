import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { SERVER_HIT } from "../util/API";
import PromptDialog from "../components/promptDialog";
import AddAcademyStudent from "../components/addAcademyStudent";
import { useSelector } from "react-redux";
import { get } from "lodash";
import AcademyStudentFees from "../components/AcademyStudentFees";
import moment from "moment";

const Academy = () => {
  const { batchList } = useSelector((state) => state.academy)
  const [ academyStudentList, setAcademyStudentList ] = useState([]);
  const [ addAcademyStudentModel, setAddAcademyStudentModel ] = useState(false);
  const [ studentDetails, setStudentDetails ] = useState({});
  const [ showDeleteDialog, setDeleteDialog ] = useState(false);
  const [ showStudentFeesModal, setStudentFeesModal ] = useState(false);
  const [ searchStudentName, setSearchStudentName ] = useState('');

  useEffect(() => {
    getAcademyStudents();
  }, []);

  const getAcademyStudents = async () => {
    const { status, data } = await SERVER_HIT.get('?module=academy&operation=academy_student_list', {});
    if (status === 200) {
      setAcademyStudentList(data);
    }
  }

  const getBatchName = (batchId) => {
    return get(batchList.find(({batch_id, batch_name}) => batch_id === batchId), 'batch_name', '');
  }

  const deleteAcademyStudent = async () => {
    const { status, data } = await SERVER_HIT.delete(`?module=academy&operation=delete_student&academy_student_id=${studentDetails.academy_student_id}`, {});
    if (status === 200) {
      if (data.message === 'Data deleted') {
        getAcademyStudents();
      } else {
        alert(data.message)
      }
    }
  }

  return (
    <>
      <Row className="px-1 py-1">
        <Col className="col-8 fs-5 pt-1 fb">
          <b>Academy Student List</b>
        </Col>
        
        <Col className="col-4 text-end pt-1 pb-1 d-flex">
          <InputGroup style={{ marginRight: '10px' }}>
            <Form.Control
              size="sm"
              placeholder="Search by Academy Student Name"
              value={searchStudentName}
              onChange={(event) => setSearchStudentName(event.target.value)}
            />
            {/* <Button variant="outline-primary" size="sm" onClick={searchStudent}>
              Search
            </Button> */}
          </InputGroup>
          <Button
            style={{ width: '150px' }}
            size="sm"
            variant="outline-primary"
            onClick={() => {
              setAddAcademyStudentModel(true);
              setStudentDetails({});
            }}
          >Add Student</Button>
        </Col>
      </Row>
      <div>
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col" width="12%" className="text-center">Student</th>
              <th scope="col" width="17%">Name</th>
              <th scope="col" width="13%" className="text-center">Whatsapp Number</th>
              <th scope="col" width="13%" className="text-center">Phone Number</th>
              <th scope="col" width="15%" className="text-center">Batch</th>
              <th scope="col" width="20%" className="text-center">Membership Duration</th>
              <th scope="col" width="10%" className="text-center"></th>
            </tr>
          </thead>
          <tbody>
            {
              academyStudentList.map((student) => {
                return (searchStudentName === '' || student.student_name.toLowerCase().indexOf(searchStudentName.toLowerCase()) > -1) ? (
                  <tr key={student.academy_student_id}>
                    <td className="text-center">
                      <button
                        className="btn btn-outline-secondary btn-sm me-1"
                        type="button"
                        onClick={() => {
                          setAddAcademyStudentModel(true);
                          setStudentDetails({
                            ...student,
                            readOnly: false
                          })
                        }}
                      >
                        Edit
                      </button>

                      <button
                        className="btn btn-outline-secondary btn-sm ms-1" 
                        type="button"
                        onClick={() => {
                          setDeleteDialog(true);
                          setStudentDetails(student)
                        }}
                      >
                        Delete
                      </button>
                    </td>
                    <td>
                      <a
                        className="text-decoration-underline pointer"
                        onClick={() => { 
                          setAddAcademyStudentModel(true);
                          setStudentDetails({
                            ...student,
                            readOnly: true
                          })
                        }}
                      >
                        {student.student_name}
                      </a>
                    </td>
                    <td className="text-center">{student.whatsapp_mobile_number}</td>
                    <td className="text-center">{student.phone_number}</td>
                    <td className="text-center">
                      {getBatchName(student.batch_id)}
                    </td>
                    <td className="text-center">
                      {
                        student.start_date && student.end_date ? (
                          <>
                            <Badge className="btn-outline-secondary">
                              {moment(student.start_date).format("DD-MM-YYYY")}
                            </Badge>
                            &nbsp;To&nbsp;
                            <Badge className="btn-outline-secondary">
                              {moment(student.end_date).format("DD-MM-YYYY")}
                            </Badge>
                          </>
                        ) : <></>
                      }
                    </td>
                    <td className="text-center">
                      <button
                        className={`btn btn-sm me-1 ${student.start_date && student.end_date ? 'btn-success' : 'btn-outline-secondary'}`}
                        type="button"
                        onClick={() => {
                          setStudentFeesModal(true);
                          setStudentDetails(student);
                        }}
                      >
                        { student.start_date ? 'Edit' : 'Add' } / View
                      </button>
                    </td>
                  </tr>
                ) : <></>
              })
            }
          </tbody>
        </table>
      </div>
      <AddAcademyStudent studentDetail={studentDetails} showModal={addAcademyStudentModel} hideModal={(resp = false) => {
        setAddAcademyStudentModel(false);
        if (resp) getAcademyStudents();
      }}/>
      <AcademyStudentFees studentDetails={studentDetails} showModal={showStudentFeesModal} hideModal={(resp = false) => {
        setStudentFeesModal(false);
        if (resp) getAcademyStudents();
      }}/>
      <PromptDialog
        show={showDeleteDialog}
        handleClose={(resp) => {
          setDeleteDialog(!showDeleteDialog);
          if (resp) {
            deleteAcademyStudent();
          }
        }}
        title="Delete Academy Student"
        message="Do You want to delete academy student?"
      />
    </>
  );
};

export default Academy;
