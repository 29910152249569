import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SERVER_HIT } from '../util/API';

function ChangePassword({
  showModal,
  hideModal
}) {
  const [ passwordDetails, setPasswordDetails ] = useState({
    oldPassword: '',
    newPassword: '',
    confirmationpassword: ''
  });
  const [ loading, setLoading ] = useState(false);
  const [ validated, setValidated ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  
  useEffect(() => {
    if (showModal) {
      setPasswordDetails({
        oldPassword: '',
        newPassword: '',
        confirmationpassword: ''
      });
    }
  }, [ showModal ])

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (passwordDetails.newPassword !== passwordDetails.confirmationpassword) {
        setErrorMessage('New password and confirmation password should be same.')
      } else {
        setErrorMessage('');
        if (passwordDetails.oldPassword !== '') {
          setLoading(true);
          const { status, data } = await SERVER_HIT.post(`?module=user&operation=change_password`, {
            username: localStorage.getItem('CBusername'),
            old_password: passwordDetails.oldPassword,
            new_password: passwordDetails.newPassword
          });
          setLoading(false);
          if (status === 200) {
            if (data.code === 'password_change') {
              setErrorMessage('Password has been changed');
              setTimeout(() => {
                hideModal();
              }, 1500);
            } else if (data.code === 'invalid_old_password') {
              setErrorMessage('Please enter valid old password');
            } else if (data.code === 'something_went_wrong') {
              setErrorMessage('Something went wrong while saving password.');
            }
          }
        }
      }
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={hideModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                autoFocus
                value={passwordDetails.oldPassword}
                onChange={(event) => setPasswordDetails({
                  ...passwordDetails,
                  oldPassword: event.target.value
                })}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter old password.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="new-password"
                autoFocus
                value={passwordDetails.newPassword}
                onChange={(event) => setPasswordDetails({
                  ...passwordDetails,
                  newPassword: event.target.value
                })}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter new password.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Confirmation Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmation-password"
                autoFocus
                value={passwordDetails.confirmationpassword}
                onChange={(event) => setPasswordDetails({
                  ...passwordDetails,
                  confirmationpassword: event.target.value
                })}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter confirmation password.</Form.Control.Feedback>
            </Form.Group>
            
            <div className='error text-center text-error'>
              {errorMessage}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            { loading ? 'Updaging Password...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangePassword;