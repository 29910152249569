import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SERVER_HIT } from '../util/API';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import * as formik from 'formik';
import * as yup from 'yup';
import { get } from 'lodash';
import { ACADEMY_MESSAGE } from '../constants';
import { AiOutlineWhatsApp } from 'react-icons/ai';

function AcademyStudentFees({
  studentDetails,
  showModal,
  hideModal
}) {
  const { sportList, batchList } = useSelector((state) => state.academy);
  const [ showLoader, setLoader ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ membershipList, setMembershipList ] = useState([]);
  const formRef = useRef()
  const { Formik } = formik;
  const schema = yup.object().shape({
    start_date: yup.date().required(),
    end_date: yup.date().required(),
    batch_id: yup.string().required(),
    sport_id: yup.string().required(),
    amount_paid: yup.number().required().min(1, "Min is 0")
  });

  const academy_student_id = get(studentDetails, 'academy_student_id', '');
  useEffect(() => {
    if (showModal) {
      getMemberShipData();
    }
  }, [ showModal ]);

  const getMemberShipData = async () => {
    const { status, data } = await SERVER_HIT.get(`?module=academy&operation=get_academy_student_fee_list&academy_student_id=${studentDetails?.academy_student_id}`, {});
    if (status === 200) {
      setMembershipList(data);
    }
  }

  const handleSubmit = async (event) => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  };

  const getBatchName = (batchId) => {
    return get(batchList.find(({batch_id}) => batch_id === batchId), 'batch_name', '');
  }
  
  const getSportName = (sportId) => {
    return get(sportList.find(({sport_id}) => sport_id === sportId), 'sport_name', '');
  }

  const prepareWhatsappMessage = () => {
    var message = ACADEMY_MESSAGE;
    message = message.replace("{{NAME}}", get(studentDetails, 'student_name', ''));
    message = message.replace("{{BATCH_TIME}}", getBatchName(get(studentDetails, 'batch_id', '')));
    message = message.replace("{{SPORT}}", getSportName(get(studentDetails, 'sport_id', '')));
    message = message.replace("{{DATE}}", `${moment(get(studentDetails, 'start_date', null)).format('DD-MM-YYYY')} to ${moment(get(studentDetails, 'end_date', null)).format('DD-MM-YYYY')}`);
    message = message.replace("{{FEES}}", get(studentDetails, 'amount_paid', ''));
    message = message.replace("{{PAID_DATE}}", moment(get(studentDetails, 'paid_on', '')).format('DD-MM-YYYY'));
    return message;
  }

  return (
    <>
      <Modal show={showModal} onHide={hideModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Manage Student Fees</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3}>
              <Formik
                enableReinitialize={true}
                innerRef={formRef}
                validationSchema={schema}
                onSubmit={async (values) => {
                  setLoader(true);
                  setErrorMessage('');
                  const { status, data } = await SERVER_HIT.post(`?module=academy&operation=save_academy_fees`, {
                    academy_student_fees_id: get(studentDetails, 'academy_student_fees_id', ''),
                    academy_student_id: academy_student_id,
                    batch_id: values.batch_id,
                    sport_id: values.sport_id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                    amount_paid: values.amount_paid,
                  });
                  if (status === 200) {
                    if (data.message === 'Academy student fees saved') {
                      getMemberShipData();
                      setErrorMessage(data.message);
                      setTimeout(() => {
                        hideModal(true);
                        setErrorMessage('');
                      }, 1000);
                    } else {
                      setErrorMessage(data.message);
                    }
                  }
                  setLoader(false);
                }}
                initialValues={{
                  start_date: moment(get(studentDetails, 'start_date', null)).format('YYYY-MM-DD'),
                  end_date: moment(get(studentDetails, 'end_date', null)).format('YYYY-MM-DD'),
                  batch_id: get(studentDetails, 'batch_id', ''),
                  sport_id: get(studentDetails, 'sport_id', ''),
                  amount_paid: get(studentDetails, 'amount_paid', ''),
                }}
              >
                {({ handleChange, values, setFieldValue, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        // min={studentDetails.membership_id != '' ? startDate : today}
                        name="start_date"
                        autoFocus
                        value={values.start_date}
                        onChange={handleChange}
                        required
                        isInvalid={!!errors.start_date}
                      />
                      <Form.Control.Feedback type="invalid">Please enter valid start date</Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="end_date"
                        min={values.start_date}
                        required
                        autoFocus
                        value={values.end_date}
                        onChange={handleChange}
                        isInvalid={!!errors.end_date}
                      />
                      <Form.Control.Feedback type="invalid">Please enter valid end date</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Batch</Form.Label>
                      <Form.Select
                        name="batch_id"
                        value={values.batch_id}
                        onChange={handleChange}
                        isInvalid={!!errors.batch_id}
                        required
                      >
                        <option value=''>Select Batch</option>
                        {
                          batchList.map(({ batch_id, batch_name }) => <option key={`batch_${batch_id}`} value={batch_id}>{batch_name}</option>)
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">Please select batch</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Sport</Form.Label>
                      <Form.Select
                        name="sport_id"
                        value={values.sport_id}
                        onChange={handleChange}
                        required
                        isInvalid={!!errors.sport_id}
                      >
                        <option value=''>Select Sport</option>
                        {
                          sportList.map(({ sport_id, sport_name }) => <option key={`school_${sport_id}`} value={sport_id}>{sport_name}</option>)
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">Please select sport</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Amount Paid</Form.Label>
                      <Form.Control
                        type="amount_paid"
                        required
                        value={values.amount_paid}
                        onChange={async (e) => handleChange('amount_paid')(e)}
                        isInvalid={!!errors.amount_paid}
                      />
                      <Form.Control.Feedback type="invalid">Please enter valid amount.</Form.Control.Feedback>
                    </Form.Group>

                    <div className='error text-center text-error'>
                      {errorMessage}
                    </div>

                    <div className='text-end'>
                      <Button variant="primary" onClick={handleSubmit} className='mt-2'>
                        { showLoader ? 'Saving...' : 'Save'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
            <Col>
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th width="25%"  scope="col" className="text-center">Sport</th>
                    <th width="25%"  scope="col" className="text-center">Batch</th>
                    <th width="30%" scope="col" className="text-center">Duration</th>
                    <th width="20%"  scope="col" className="text-center">Amount Paid</th>
                  </tr>
                </thead>
                {
                  membershipList.length > 0 && (
                    <tbody>
                      {
                        membershipList.map(({ sport_id, batch_id, start_date, end_date, amount_paid }) => {
                          return (
                            <tr>
                              <td scope="col" className="text-center">
                                {getSportName(sport_id)}
                              </td>
                              <td scope="col" className="text-center">
                                {getBatchName(batch_id)}
                              </td>
                              <td scope="col" className="text-center">
                                {moment(start_date).format("DD-MM-YYYY")} 
                                &nbsp;To&nbsp;
                                {moment(end_date).format("DD-MM-YYYY")}
                              </td>
                              <td scope="col" className="text-center">₹{amount_paid}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  )
                }
              </table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            get(studentDetails, 'whatsapp_mobile_number', '') && get(studentDetails, 'paid_on', '') !== '' && get(studentDetails, 'paid_on', '')  !== null && (
              <Button variant="success" onClick={() => window.open(`https://api.whatsapp.com/send?phone=91${get(studentDetails, 'whatsapp_mobile_number', '')}&text=${prepareWhatsappMessage()}`)} disabled={showLoader}>
                <AiOutlineWhatsApp size={20} style={{ verticalAlign: 'sub'}} />
              </Button>
            )
          }
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AcademyStudentFees;